import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import {
  AuthService,
  SocialUser,
  GoogleLoginProvider
} from 'angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {

  public serverUrl = environment.apiBaseUrl;
  constructor(public authService: AuthService,  public http: HttpClient) {
   }

  //  signInWithGoogle() {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }

  signOut() {
    this.authService.signOut();
    localStorage.clear();
  }
  googleAuth(token, email) {
    return this.http.post(this.serverUrl + 'gAuth?email=' + email, JSON.stringify(token));
  }
  getAccessToken() {
    return JSON.parse(localStorage.getItem('user'));
  }

  isLoggedIn() {
    return !!localStorage.getItem('user');
  }
}
