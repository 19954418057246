import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css']
})
export class ErrorsComponent implements OnInit {
  public index: number;
  public message = '';
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.message = params.get('q_err');
    });
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.index = parseInt(params.get('id'), 0);
    });

  }
}
