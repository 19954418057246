import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AuthService, GoogleLoginProvider } from 'angularx-social-login';
import { FlashMessagesService } from 'angular2-flash-messages';

import { EmployeeService } from '../services/employee.service';
import { GoogleAuthService } from '../services/google-auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PwaService } from '../services/pwa.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  permissionSlug: string = environment.adminGrpPermSlug;
  cabCancelSlug: string = environment.canCancelCabSlug;
  canRedirect = false;
  index = 0;
  queryParameter;
  constructor(private gService: GoogleAuthService,
    private empService: EmployeeService,
    private route: Router,
    public activeRoute: ActivatedRoute,
    private socialAuth: AuthService,
    private flashMessage: FlashMessagesService,
    private ngxService: NgxUiLoaderService,
    public pwa: PwaService) { }

  ngOnInit() {
    this.ngxService.stop();
    this.activeRoute.queryParamMap.subscribe((params: ParamMap) => {
      this.index = parseInt(params.get('can_req_id'), 0);
      if (this.index) {
        this.queryParameter = 'can_req_id';
        this.canRedirect = true;
      } else {
        this.index = parseInt(params.get('del_req_id'), 0);
        if (this.index) {
          this.queryParameter = 'del_req_id';
          this.canRedirect = true;
        }
      }
    });
  }

  installPwa() {
    this.pwa.promptEvent.prompt();
  }

  googleLogin() {
    this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user) => {
        if (user) {
          if (user.email.indexOf('regalix-inc') !== -1) {
            this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
            this.empService.getUserRole(user.email)
              .subscribe(res => {
                if (res['status'] && res['data']['status'] === 1) {
                  console.log(res)
                  if (res['data']['permissions'].includes(this.permissionSlug)) {
                    this.gService.googleAuth({ idToken: user.idToken }, user.email)
                      .subscribe((gResult) => {
                        localStorage.setItem('user', JSON.stringify(gResult['data']));
                        if (this.canRedirect) {
                          this.route.navigate(['admin/acknowledged'], { queryParams: { [this.queryParameter]: this.index } });
                        } else if (gResult['status'] === true) {
                          this.route.navigate(['admin/upcoming-list']);
                        }
                      },
                        error => {
                          console.log(error);
                          this.ngxService.stop();
                          let message = '';
                          if (error.error) {
                            message = error.error['exc'];
                          }
                          if (error.status === 422) {
                            this.route.navigate(['login']);
                          } else {
                            this.route.navigate(['error', error.status], { queryParams: { q_err: message } });
                          }
                        });
                  } else if (res['data']['permissions'].includes(this.cabCancelSlug)) { 
                    this.gService.googleAuth({ idToken: user.idToken }, user.email)
                    .subscribe((gResult) => {
                      if (gResult['status'] === true) {
                        localStorage.setItem('user', JSON.stringify(gResult['data']));
                        this.route.navigate(['employee/journey']);  
                        }
                    },
                      error => {
                        console.log(error);
                        this.ngxService.stop();
                        let message = '';
                        if (error.error) {
                          message = error.error['exc'];
                        }
                        if (error.status === 422) {
                          this.route.navigate(['login']);
                        } else {
                          this.route.navigate(['error', error.status], { queryParams: { q_err: message } });
                        }
                      });
                  }
                   else {
                      if (res['data']['roles'] === 'PNL Head') {
                        this.gService.googleAuth({ idToken: user.idToken }, user.email)
                          .subscribe((gResult) => {
                            if (gResult['status'] === true) {
                              localStorage.setItem('user', JSON.stringify(gResult['data']));
                              this.route.navigate(['employee/pnl-head']);
                            }
                          },
                            error => {
                              console.log(error);
                              this.ngxService.stop();
                              let message = '';
                              if (error.error) {
                                message = error.error['exc'];
                              } else {
                              }
                              if (error.status === 422) {
                                this.route.navigate(['login']);
                              } else {
                                this.route.navigate(['error', error.status], { queryParams: { q_err: message } });
                              }
                            });
                        this.gService.googleAuth({ idToken: user.idToken }, user.email)
                          .subscribe((gResult) => {
                            if (gResult['status'] === true) {
                              localStorage.setItem('user', JSON.stringify(gResult['data']));
                              this.route.navigate(['employee/journey']);
                            }
                          },
                            error => {
                              console.log(error);
                              this.ngxService.stop();
                              let message = '';
                              if (error.error) {
                                message = error.error['exc'];
                              }
                              if (error.status === 422) {
                                this.route.navigate(['login']);
                              } else {
                                this.route.navigate(['error', error.status], { queryParams: { q_err: message } });
                              }
                            });
                      }
                      else {
                      this.ngxService.stop();  
                      this.flashMessage.show('Oops!! <br/> You do not have access to the tool. <br/> If you think this is not right, please raise a mail to tat@regalix-inc.com.',
                        { cssClass: 'alert-danger', position: top, timeout: 20000 });
                      this.signOut();
                      }
                    }
                }
                else if(res['status'] && res['data']['status'] === 0) {
                  this.ngxService.stop();
                  this.flashMessage.show('Your account is in-active',
                    { cssClass: 'alert-danger', position: top, timeout: 20000 });
                }
                 else {
                  this.ngxService.stop();
                  // tslint:disable-next-line: max-line-length
                  this.flashMessage.show('Oops!! <br/> You do not have access to the tool. <br/> If you think this is not right, please raise a mail to tat@regalix-inc.com.',
                    { cssClass: 'alert-danger', position: top, timeout: 20000 });
                  this.signOut();
                  this.route.navigate(['login']);
                }
              },
                error => {
                  console.log(error);
                  this.ngxService.stop();
                  let message = '';
                  if (error.error) {
                    message = error.error['exc'];
                  }
                  if (error.status === 422) {
                    this.route.navigate(['login']);
                  } else {
                    this.route.navigate(['error', error.status], { queryParams: { q_err: message } });
                  }
                });
          } else {
            this.ngxService.stop();
            this.flashMessage.show('You need to be Regalix staff to access this tool',
              { cssClass: 'alert-danger', position: top, timeout: 20000 });
            this.signOut();
            this.route.navigate(['login']);
          }
        } else {
          this.ngxService.stop();
          this.flashMessage.show('You need to be Regalix staff to access this tool',
            { cssClass: 'alert-danger', position: top, timeout: 20000 });
          this.signOut();
          this.route.navigate(['login']);
        }
      },
        (reason) => {
          this.ngxService.stop();
          this.flashMessage.show(reason,
            { cssClass: 'alert-danger', position: top, timeout: 10000 });
        });
  }

  signOut() {
    this.socialAuth.signOut();
  }

}
