import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CabCancelList } from '../models/listCabCancel';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  public serverURL = environment.apiBaseUrl;
  constructor(public http: HttpClient) { }

  getUserRole(email: string) {
    return this.http.get(this.serverURL + 'access?email=' + email);
  }

  getCancelList(): Observable<CabCancelList[]> {
    return this.http.get<CabCancelList[]>(this.serverURL + 'employee/cab_cancel');
  }
  getCabCancelDateById(id: number) {
    return this.http.get(this.serverURL + 'employee/cab_cancel/' + id);
  }

  updateDatesById(id: number, data) {
    return this.http.put(this.serverURL + 'employee/cab_cancel/' + id, data);
  }

  postCabCancel(data) {
    return this.http.post(this.serverURL + 'employee/cab_cancel', data);
  }

  deleteDate(id: number, skey: string, name: string) {
    return this.http.delete(this.serverURL + 'employee/cab_cancel?id=' + id + '&sKey=' + skey + '&name=' + name);
  }
}
