import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import {GoogleAuthService} from './google-auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(GoogleAuthService);
    const token = authService.getAccessToken();
    if (token) {
      return next.handle(
        req.clone({
          headers:  req.headers.append('Authorization', 'Bearer ' + token['access_token'])
      })
      );
    }
    return next.handle(req);
  }
}
